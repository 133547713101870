import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";

export const translatePath = (
  pathTo: "home" | "contactus" | "hosts" | "iciLive",
  language: string
): string => {
  return language === "en"
    ? translationEN.routes[pathTo]
    : translationFR.routes[pathTo];
};
